<template>
  <legal-page-template :locale="locale">
    <main class="legal-page__content legal-page-content">
      <h1 class="legal-page-content__title">{{ $t('legal.patientConsent.title') }}</h1>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header legal-page-article__header--with-margin">
          {{ $t('legal.patientConsent.ourCommitment') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.yourPersonalDataVeryImportant') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.universkinCommittedToStrictly') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.dataCoveredByThisTreatment') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.identifiableData') }}
          </p>

          <div class="legal-article-section__indent-section">
            <p class="legal-article-section__paragraph">
              <b>&bull;</b>
              {{ $t('legal.patientConsent.civilStatusInformation') }}
            </p>

            <div class="legal-article-section__indent-section">
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.civilInfo.yourFirstAndLastName') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.civilInfo.yourDateOfBirth') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.civilInfo.yourPhoto') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.civilInfo.yourAddress') }}
              </p>
            </div>

            <p class="legal-article-section__paragraph">
              <b>&bull;</b>
              {{ $t('legal.patientConsent.contactInformation') }}
            </p>

            <div class="legal-article-section__indent-section">
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.contactInfo.yourEmailAddress') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.contactInfo.yourPhoneNumber') }}
              </p>
            </div>
          </div>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.qualifyingData') }}
          </p>

          <div class="legal-article-section__indent-section">
            <p class="legal-article-section__paragraph">
              <b>&bull;</b>
              {{ $t('legal.patientConsent.locationInformation') }}
            </p>

            <div class="legal-article-section__indent-section">
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.locationInfor.yourAreaOfResidence') }}
              </p>
            </div>

            <p class="legal-article-section__paragraph">
              <b>&bull;</b>
              {{ $t('legal.patientConsent.healthInformation') }}
            </p>

            <div class="legal-article-section__indent-section">
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.healthInfo.yourPathology') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.healthInfo.familyHistory') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.healthInfo.dataRelatedToHealthCare') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.healthInfo.alterationsAnomalies') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.healthInfo.riskySituations') }}
              </p>
              <p class="legal-article-section__paragraph">
                ◦ {{ $t('legal.patientConsent.healthInfo.photosRelated') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.patientConsent.mainAimsAndLegalBasis') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.thisDataUsedForUniverskinOnline') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.thisDataAlsoUsedToAnalyze') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.patientConsent.treatment') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.asPartOfMainTreatment') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.universkinOnlyHaveAccess') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.qualifyingDataMayBeProcessed') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.qualifyingAndIdentifiableData') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.yourDataStoredForPeriod') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.patientConsent.yourRights') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.asSubjectYouHavePossibility') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.rightToInformation') }}
            {{ $t('legal.patientConsent.beforeAndDuringEachDataCollection') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.rightOfAccess') }}
            {{ $t('legal.patientConsent.youEntitledToAskUniverskinToProvide') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.rightOfRectification') }}
            {{ $t('legal.patientConsent.youEntitledToAskUniverskinToRectify') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.rightToErasure') }}
            {{ $t('legal.patientConsent.youEntitledToAskUniverskinToRemove') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.rightToLimitationOfTreatment') }}
            {{ $t('legal.patientConsent.youCanAskUniverskinThatSomeOfYourData') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.rightOfObjection') }}
            {{ $t('legal.patientConsent.youMayObjectToProcessing') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.patientConsent.rightToDataHolding') }}
            {{ $t('legal.patientConsent.youCanHaveYourDataTransmitted') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.inOrderToAllowExerciseOfYourRights') }}
            <a href="mailto:UNIVERSKIN.RGPD@mydata-trust.info" class="legal-page-content__link"
              >UNIVERSKIN.RGPD@mydata-trust.info</a
            >
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.asSubjectOfDataYouHaveRight') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.patientConsent.supervisoryAuthorityInFrance') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header legal-page-article__header--with-margin">
          {{ $t('legal.patientConsent.nonApplicableToPersonsUnder') }}
        </div>
        <div class="legal-page-article__title">
          {{ $t('legal.patientConsent.secondaryAimsAndExplicitConsents') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            {{ $t('legal.patientConsent.identifiableData') }}
          </p>

          <div class="legal-article-section__indent-section">
            <p class="legal-article-section__paragraph">
              &bull; {{ $t('legal.patientConsent.marketing') }}
            </p>

            <p class="legal-article-section__paragraph">
              {{ $t('legal.patientConsent.yourIdentifiableDataCouldAlso') }}
            </p>
          </div>

          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            {{ $t('legal.patientConsent.qualifyingDataExplicit') }}
          </p>

          <div class="legal-article-section__indent-section">
            <p class="legal-article-section__paragraph">
              {{ $t('legal.patientConsent.qualifyingDataConsideredToPseudonymous') }}
            </p>

            <p class="legal-article-section__paragraph">
              &bull; {{ $t('legal.patientConsent.operationalOptimization') }}
            </p>

            <p class="legal-article-section__paragraph">
              {{ $t('legal.patientConsent.universkinMethodologyPartOfRegular') }}
            </p>

            <p class="legal-article-section__paragraph">
              &bull; {{ $t('legal.patientConsent.scientificResearch') }}
            </p>

            <p class="legal-article-section__paragraph">
              {{ $t('legal.patientConsent.universkinResearchersCouldUseYour') }}
            </p>

            <p class="legal-article-section__paragraph">
              {{ $t('legal.patientConsent.atAnyTimeYouHavePossibilityToWithDraw') }}
              <a href="mailto:UNIVERSKIN.RGPD@mydata-trust.info" class="legal-page-content__link"
                >UNIVERSKIN.RGPD@mydata-trust.info</a
              >
            </p>
          </div>
        </div>
      </div>
    </main>
  </legal-page-template>
</template>

<script>
import LegalPageTemplate from '@/components/templates/LegalPageTemplate';

export default {
  name: 'PagePatientConsent',
  components: { LegalPageTemplate },
  props: {
    locale: {
      type: String,
      default: ''
    }
  },
  metaInfo: {
    title: 'Patient Consent',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-pages/content-styles';
</style>
